<template>
    <div class="page">
        <div class="content">
            <div class="time_content">
                <div class="date_content" @click="show = true">
                    <span>请选择日期</span>
                    <span>{{ listQuery.start || "暂无" }}</span>
                </div>
                <div>
                    <img src="../assets/bk.png" alt="">
                    <span>{{ day }} 天</span>
                </div>
                <div class="date_content" @click="show = true">
                    <span>请选择日期</span>
                    <span>{{ listQuery.end || "暂无" }}</span>
                </div>
            </div>
            <div class="total_content">
                <div class="total_box">
                    <div>
                        <span>订单金额(元)</span>
                        <span>{{ statData.amount }}</span>
                    </div>
                    <div>
                        <span>订单量(笔)</span>
                        <span>{{ statData.ordernum }}</span>
                    </div>
                </div>
            </div>
            <div class="list_conetnt" v-if="list.length > 0">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="row">
                        <div>订单编号: </div>
                        <div>
                            {{ item.order_sn }}
                        </div>
                    </div>
                    <div class="row">
                        <div>订单金额(元):</div>
                        <div class="num">
                            ￥{{ item.total_amount }}
                        </div>
                    </div>
                    <div class="row">
                        <div>采购商:</div>
                        <div>
                            {{ item.buyer_name }}
                        </div>
                    </div>
                    <div class="row">
                        <div>供应商:</div>
                        <div>
                            {{ item.seller_name }}
                        </div>
                    </div>
                    <div class="row">
                        <div>支付时间:</div>
                        <div>
                            {{ item.create_at }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <van-empty description="暂无订单信息！" />
            </div>
        </div>
        <van-calendar v-model="show" type="range" :allow-same-day="true" :min-date="minDate" :max-date="maxDate"
            color="#31b158" @confirm="onConfirm" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            listQuery: {
                start: "",
                end: "",
                role_type: ""
            },
            day: "1",
            minDate: new Date(2023, 0, 1), //可选最早时间
            maxDate: new Date(),
            list: [],
            statData: "",
            access_token: "",
            mobile: ""
        }
    },
    created() {
        if (window.sessionStorage.getItem("role_type")) {
            this.listQuery.role_type = window.sessionStorage.getItem("role_type")
        }
        this.mobile = this.getParameterByName("mobile")
        var that = this
        if (!window.sessionStorage.getItem("access_token")) {
            this.$dialog.alert({
                title: "提醒",
                message: '您暂未登录！是否前往登录?',
                showConfirmButton: true,
                showCancelButton: true
            }).then(() => {
                that.$router.push({
                    path: '/Login',
                    query: {
                        mobile: that.mobile
                    }
                });
            }).catch(() => {
                that.$router.push({
                    path: '/index',
                    query: {
                        mobile: that.mobile
                    }
                });
            });
        } else {
            this.access_token = window.sessionStorage.getItem("access_token")
            this.getList()
            this.getStat()
        }
    },
    mounted() {
        this.pushHistory()
        //  监听popstate事件
        window.addEventListener('popstate', this.listenPopstate);
    },

    beforeDestroy() {
        window.removeEventListener('popstate', this.listenPopstate);
    },
    methods: {
        listenPopstate() {
            if (this.$route.path === '/OrderList') {
                this.$router.push({
                    path: '/index',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
            //  跳转小程序页面相关逻辑
        },
        pushHistory() {
            window.history.pushState(null, null, null)
        },
        // 解析URL
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            //匹配所有符合条件的，并取最后一个
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'g');
            var results = url.match(regex);
            var tempResults = results != null && results[results.length - 1] != undefined ? results[results.length - 1] : '';
            var finalResults = regex.exec(tempResults);
            if (!finalResults) return "";
            if (!finalResults[2]) return '';
            return decodeURIComponent(finalResults[2].replace(/\+/g, " "));
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            return `${year}-${month}-${day}`;
        },
        onConfirm(date) {
            const [start, end] = date;
            this.show = false;
            this.day = (new Date(date[1]).getTime() - new Date(date[0]).getTime()) / (1 * 1000 * 60 * 60 * 24) + 1
            this.listQuery.start = this.formatDate(start);
            this.listQuery.end = this.formatDate(end);
            this.getList()
            this.getStat()
        },
        async getList() {
            const { data: res } = await this.$https.post('/mobileh5/mini-program/get-trade-list?access_token=' + window.sessionStorage.getItem("access_token"), this.listQuery)
            if (res.resultCode == "1") {
                this.list = res.resultInfo
            }
        },
        async getStat() {
            const { data: res } = await this.$https.post('/mobileh5/mini-program/order-stat?access_token=' + window.sessionStorage.getItem("access_token"), this.listQuery)
            if (res.resultCode == "1") {
                this.statData = res.resultInfo
            }
        }
    },
}
</script>

<style lang="less" scoped>
[v-cloak] {
    display: none
}

.page {
    background-color: #f1f4f9;
    user-select: none;
    width: 100%;
    height: 100vh;
}

.content {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.time_content {
    width: 100%;
    height: 52px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;

    >div {
        flex: 1;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    >div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 12px;
        color: #9ea5b8;

        img {
            width: 100%;
            height: 23px;
        }

        span {
            position: absolute;
        }
    }
}

.total_content {
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    .total_box {
        width: 94%;
        height: 100%;
        background: linear-gradient(90deg, #00d379, #00b470);
        border-radius: 10px;
        display: flex;

        >div {
            flex: 1;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 20px;

            >span:nth-child(1) {
                margin-bottom: 10px;
                font-size: 16px;
            }
        }
    }
}

.date_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 15px;

    >span:nth-child(1) {
        color: #9ea5b8;
        font-size: 13px;
    }
}

.list_conetnt {
    width: 100%;
    height: calc(100vh - 60px - 120px);
    margin-top: 8px;
    box-sizing: border-box;
    padding: 0 10px;
    overflow-y: auto;

    .item {
        background-color: #fff;
        width: 100%;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 8px;
        margin-bottom: 10px;

        .row {
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .item:nth-last-child(1) {
        margin-bottom: 0;
    }
}

.num {
    font-weight: 600;
    font-style: 18px;
    color: #31b158;
}
</style>