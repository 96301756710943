<template>
    <div class="page">
        <div class="top_content">
            <div class="btn_content" v-if="merchants_type == 3">
                <div :class="role_type == 1 ? 'active' : ''" @click="setType(1)">
                    <div>采购商</div>
                </div>
                <div :class="role_type == 2 ? 'active' : ''" @click="setType(2)">
                    <div>供应商</div>
                </div>
            </div>
            <div class="btn_content" v-else-if="merchants_type == ''">
                <div class="active">
                    <div>采购商</div>
                </div>
                <div class="">
                    <div>供应商</div>
                </div>
            </div>
            <div class="btn_content" v-else-if="merchants_type != '' && merchants_type != 3">
                <div :class="role_type == 1 ? 'active' : ''">
                    <div>采购商</div>
                </div>
                <div :class="role_type == 2 ? 'active' : ''">
                    <div>供应商</div>
                </div>
            </div>
            <div class="swiper">
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#31b158" v-if="imgList.length > 0">
                    <van-swipe-item v-for="(item, index) in imgList" :key="index">
                        <img class="img" :src="item" alt="加载失败">
                    </van-swipe-item>
                </van-swipe>
            </div>
        </div>
        <div class="tab">
            <div class="tab_content">
                <div class="tab_list">
                    <div @click="toStatistics">
                        <img class="tab_img" src="../assets/icon_shourutongji.png" alt="">
                        <span>收入统计</span>
                    </div>
                    <div @click="toOrderList">
                        <img class="tab_img" src="../assets/icon_dingdanliebiao.png" alt="">
                        <span>订单列表</span>
                    </div>
                    <div @click="toEquipmentReceive" v-if="role_type != 1">
                        <img class="tab_img" src="../assets/icon_shebeilngong.png" alt="">
                        <span>设备领用</span>
                    </div>
                    <div @click="toPurchaseRecharge" v-if="role_type != 2">
                        <img class="tab_img" src="../assets/icon_caigouchongzhi.png" alt="">
                        <span>采购充值</span>
                    </div>
                    <div @click="toRechargeList" v-if="role_type != 2">
                        <img class="tab_img" src="../assets/icon_chongzhijilu.png" alt="">
                        <span>充值记录</span>
                    </div>
                    <div @click="toKaipiaoApply" v-if="role_type != 2">
                        <img class="tab_img" src="../assets/icon_kaipiaoshenqing.png" alt="">
                        <span>开票申请</span>
                    </div>
                    <div @click="toOrderConfirm" v-if="role_type != 2">
                        <img class="tab_img" src="../assets/icon_qeurendingdan.png" alt="">
                        <span>确认订单</span>
                    </div>
                    <div @click="toSubsidy" v-if="role_type != 1">
                        <img class="tab_img" src="../assets/icon_shanghubutie.png" alt="">
                        <span>商户补贴</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="title_content">
            <!-- <span v-if="show">园区交易数据</span> -->
            <span>园区交易数据</span>
            <!-- <span v-else>商户交易数据</span> -->
            <!-- <div class="enter_content" @click="toLogin" v-if="show">
                立即登录
            </div> -->
        </div>
        <div class="echart_box">
            <div class="echart_content">
                <div class="echart_info">
                    <div class="echart_top">
                        <div>
                            <div>交易日成交量<br>(单)</div>
                            <div class="card-panel-num">
                                <countTo :startVal=0 :endVal="Number(data.today_num) || 0" :duration='3000'
                                    class="count" :decimals="0">
                                </countTo>
                            </div>
                        </div>
                        <div class="light"></div>
                        <div>
                            <div>交易日成交额<br>(万元)</div>
                            <div class="color2 card-panel-num">
                                <countTo :startVal=0 :endVal="Number(data.today_amount) || 0.00" :duration='5000'
                                    class="count" :decimals="2">
                                </countTo>
                            </div>
                        </div>
                        <div class="light"></div>
                        <div>
                            <div>累计成交量<br>(单)</div>
                            <div class="color3 card-panel-num">
                                <countTo :startVal=0 :endVal="Number(data.ordernum) || 0" :duration='3000' class="count"
                                    :decimals="0">
                                </countTo>
                            </div>
                        </div>
                        <div class="light"></div>
                        <div>
                            <div>累计成交额<br>(万元)</div>
                            <div class="color4 card-panel-num">
                                <countTo :startVal=0 :endVal="Number(data.total_amount) || 0.00" :duration='5000'
                                    class="count" :decimals="2">
                                </countTo>
                            </div>
                        </div>
                    </div>
                    <div class="echart_title">{{ title }}</div>
                    <div class="line_content">
                        <div id="myEcharts"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CountTo from "vue-count-to"
import wx from 'weixin-js-sdk'
export default {
    components: {
        CountTo
    },
    data() {
        return {
            mobile: "",
            imgList: [],
            data: "",
            stat: "",
            merchants_type: "",
            role_type: "",
            show: true,
            isLogin: false,
            title: ""
        }
    },
    created() {
        this.getSwiperImg()
        if (window.sessionStorage.getItem("access_token")) {
            this.show = false
        } else {
            this.show = true
        }
        if (window.sessionStorage.getItem("role_type")) {
            this.role_type = window.sessionStorage.getItem("role_type")
        }
        this.mobile = this.getParameterByName("mobile")
        if (this.mobile) {
            window.sessionStorage.setItem("mobile", this.mobile)
        }
        this.getEchartList()
        if (window.sessionStorage.getItem("merchants_type")) {
            this.merchants_type = window.sessionStorage.getItem("merchants_type")
        }

    },

    mounted() {
        this.pushHistory()
        //  监听popstate事件
        window.addEventListener('popstate', this.listenPopstate);
    },

    beforeDestroy() {
        window.removeEventListener('popstate', this.listenPopstate);
    },
    methods: {
        listenPopstate() {
            if (this.$route.path === '/index') {
                wx.miniProgram.switchTab({ url: "/pages/other/index" });
            }
            //  跳转小程序页面相关逻辑
        },
        pushHistory() {
            window.history.pushState(null, null, null)
        },
        // 解析URL
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            //匹配所有符合条件的，并取最后一个
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'g');
            var results = url.match(regex);
            var tempResults = results != null && results[results.length - 1] != undefined ? results[results.length - 1] : '';
            var finalResults = regex.exec(tempResults);
            if (!finalResults) return "";
            if (!finalResults[2]) return '';
            return decodeURIComponent(finalResults[2].replace(/\+/g, " "));
        },
        toStatistics() {
            if (!window.sessionStorage.getItem("access_token")) {
                this.$dialog.alert({
                    title: "提醒",
                    message: '您暂未登录！是否前往登录?',
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(() => {
                    this.$router.push({
                        path: '/Login',
                        query: {
                            mobile: this.mobile
                        }
                    });
                }).catch(() => {

                });
            } else {
                this.$router.push({
                    path: '/Statistics',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
        },
        toSubsidy() {
            if (!window.sessionStorage.getItem("access_token")) {
                this.$dialog.alert({
                    title: "提醒",
                    message: '您暂未登录！是否前往登录?',
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(() => {
                    this.$router.push({
                        path: '/Login',
                        query: {
                            mobile: this.mobile
                        }
                    });
                }).catch(() => {

                });
            } else {
                this.$router.push({
                    path: '/Subsidy',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
        },
        toEquipmentReceive() {
            if (!window.sessionStorage.getItem("access_token")) {
                this.$dialog.alert({
                    title: "提醒",
                    message: '您暂未登录！是否前往登录?',
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(() => {
                    this.$router.push({
                        path: '/Login',
                        query: {
                            mobile: this.mobile
                        }
                    });
                }).catch(() => {

                });
            } else {
                this.$router.push({
                    path: '/EquipmentReceive',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
        },
        toPurchaseRecharge() {
            if (!window.sessionStorage.getItem("access_token")) {
                this.$dialog.alert({
                    title: "提醒",
                    message: '您暂未登录！是否前往登录?',
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(() => {
                    this.$router.push({
                        path: '/Login',
                        query: {
                            mobile: this.mobile
                        }
                    });
                }).catch(() => {

                });
            } else {
                this.$router.push({
                    path: '/PurchaseRecharge',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
        },
        toRechargeList() {
            if (!window.sessionStorage.getItem("access_token")) {
                this.$dialog.alert({
                    title: "提醒",
                    message: '您暂未登录！是否前往登录?',
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(() => {
                    this.$router.push({
                        path: '/Login',
                        query: {
                            mobile: this.mobile
                        }
                    });
                }).catch(() => {

                });
            } else {
                this.$router.push({
                    path: '/RechargeList',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
        },
        toKaipiaoApply() {
            if (!window.sessionStorage.getItem("access_token")) {
                this.$dialog.alert({
                    title: "提醒",
                    message: '您暂未登录！是否前往登录?',
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(() => {
                    this.$router.push({
                        path: '/Login',
                        query: {
                            mobile: this.mobile
                        }
                    });
                }).catch(() => {

                });
            } else {
                this.$router.push({
                    path: '/KaipiaoApply',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
        },
        toOrderConfirm() {
            if (!window.sessionStorage.getItem("access_token")) {
                this.$dialog.alert({
                    title: "提醒",
                    message: '您暂未登录！是否前往登录?',
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(() => {
                    this.$router.push({
                        path: '/Login',
                        query: {
                            mobile: this.mobile
                        }
                    });
                }).catch(() => {

                });
            } else {
                this.$router.push({
                    path: '/OrderConfirm',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
        },
        toOrderList() {
            if (!window.sessionStorage.getItem("access_token")) {
                this.$dialog.alert({
                    title: "提醒",
                    message: '您暂未登录！是否前往登录?',
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(() => {
                    this.$router.push({
                        path: '/Login',
                        query: {
                            mobile: this.mobile
                        }
                    });
                }).catch(() => {

                });
            } else {
                this.$router.push({
                    path: '/OrderList',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
        },
        toLogin() {
            if (!window.sessionStorage.getItem("access_token")) {
                this.$dialog.alert({
                    title: "提醒",
                    message: '是否立即前往登录?',
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(() => {
                    this.$router.push({
                        path: '/Login',
                        query: {
                            mobile: this.mobile
                        }
                    });
                }).catch(() => {

                });
            }
        },
        // 获取轮播图
        async getSwiperImg() {
            const { data: res } = await this.$https.get('/mobileh5/mini-program/get-banner-images')
            if (res.resultCode == "1") {
                this.imgList = res.resultInfo
            }
            return
        },
        // 获取交易数据
        async getEchartList() {
            const { data: res } = await this.$https.post('/mobileh5/mini-program/get-home-stat?access_token=' + window.sessionStorage.getItem("access_token"), { role_type: this.role_type })
            if (res.resultCode == "1") {
                this.data = res.resultInfo.data
                this.stat = res.resultInfo.stat
                this.title = res.resultInfo.title
                this.$nextTick(() => {
                    this.initEcharts()
                });
            }
            return
        },
        // 折线图
        initEcharts() {
            // 1，挂载节点
            let myEcharts = this.$echarts.init(document.getElementById('myEcharts'));
            // 2，设置图表的属性
            let options = {
                xAxis: {
                    type: 'category',
                    name: this.show ? "日" : '月份',
                    boundaryGap: false,
                    data: this.stat.month,
                    axisLabel: {
                        textStyle: {
                            fontSize: 12  // X轴字体大小
                        }
                    }
                },
                grid: {  //方法 1
                    left: 15,
                    right: 40,
                    bottom: 10,
                    top: 40,
                    containLabel: true
                },
                legend: {
                    color: '#FECE89',
                    right: 0,
                    top: "0%",
                    textStyle: {
                        color: '#000'
                    },
                    selectedMode: false, //取消图例上的点击事件
                },
                tooltip: {
                    trigger: "axis",
                    axisLabel: {
                        textStyle: {
                            fontSize: 12  // X轴字体大小
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    name: "单位: 万元",
                    axisLabel: {
                        textStyle: {
                            fontSize: 12  // X轴字体大小
                        }
                    },
                    axisLine: {
                        show: true,
                    },
                    // y轴上的小横线
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    }
                },
                series: [{
                    name: '交易额',
                    type: 'line',
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#FECE89',
                            lineStyle: {        // 系列级个性化折线样式 
                                width: 2,
                                type: 'solid',
                            }
                        },
                        emphasis: {
                            color: '#FECE89',
                            lineStyle: {        // 系列级个性化折线样式 
                                width: 2,
                                type: 'solid',
                            }
                        },

                    },// 线条样式
                    data: this.stat.amount,
                }]
            };

            // 3，使用上面的options属性 正式生成图表
            myEcharts.setOption(options);
        },
        setType(e) {
            this.role_type = e;
            window.sessionStorage.setItem("role_type", this.role_type)
            this.getEchartList()
        },
    },
}
</script>

<style lang="less" scoped>
@import "../assets/fonts/stylesheet.css";

[v-cloak] {
    display: none
}

.page {
    background-color: #f1f4f9;
    user-select: none;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 75px;
}

.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    text-align: center;
}

.top_content {
    width: 100%;
    height: 147px;
    box-sizing: border-box;
    background: url(../assets/index_bgc.png) center no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.swiper {
    width: 360px;
    height: 176.5px;
    margin-top: 64px;
    box-sizing: border-box;
    border-radius: 8px;

    img {
        width: 360px;
        height: 176.5px;
        border-radius: 8px;
    }
}

.tab {
    width: 100%;
    display: flex;
    justify-content: center;
}

.tab_content {
    width: 360px;
    height: 228.5px;
    box-sizing: border-box;
    background-color: #fff;
    margin-top: 110px;
    padding: 10px 10px 16px 10px;
    border-radius: 10px;
}

.tab_list {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #F4F7FF 0%, #FFFFFF 100%);
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    padding-top: 10px;

    >div {
        width: 25%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        .tab_img {
            width: 51px;
            height: 51px;

        }

        span {
            opacity: 0.7;
            font-family: PingFangSC-Regular;
            font-size: 13px;
            color: #000000;
            letter-spacing: 0;
            line-height: 15px;
            font-weight: 400;
        }
    }
}

.btn_content {
    position: absolute;
    right: 0;
    top: 0;
    width: 180px;
    height: 30px;
    margin-right: 12px;
    margin-top: 20px;
    display: flex;
    color: #000;
    z-index: 999;

    >div {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;

        >div {
            width: 75px;
            height: 15px;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            letter-spacing: 0;
            text-align: center;
            line-height: 15px;
            font-weight: 600;
        }
    }

    >div:nth-child(1) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    >div:nth-child(2) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .active {
        color: #fff !important;
        background-image: radial-gradient(circle, #2285fd, #1e59eb) !important;
        box-shadow: 0 2px 10px rgba(243, 242, 242, 0.5);
    }
}

.title_content {
    width: 100%;
    height: 22.5px;
    margin-top: 19px;
    box-sizing: border-box;
    padding-left: 21px;
    padding-right: 10px;
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.echart_box {
    width: 100%;
    display: flex;
    justify-content: center;
}

.echart_content {
    width: 360px;
    height: 308px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 10px 10px 16px 10px;
    border-radius: 10px;
    margin-top: 10px;
}

.echart_info {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #F4F7FF 0%, #FFFFFF 45%);
    // display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    // padding-top: 10px;
    box-sizing: border-box;

    .echart_top {
        width: 100%;
        height: 101.5px;
        // background-color: pink;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .light {
            width: 2px;
            height: 40px;
            border-right: 2px dashed rgba(151, 151, 151, 1);
            opacity: 0.74;
        }

        >div {
            width: 25%;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            >div:nth-child(1) {
                font-family: PingFangSC-Medium;
                font-size: 13px;
                color: #000000;
                letter-spacing: 0;
                text-align: center;
                line-height: 15px;
                font-weight: 600;
                margin-bottom: 12px;
            }

            >div:nth-child(2) {
                font-family: LetsgoDigital-Regular;
                font-size: 20px;
                color: #11A0F0;
                letter-spacing: 0;
                text-align: center;
                line-height: 20px;
                font-weight: 700;
            }

            .color2 {
                color: #1F28F3 !important;
            }

            .color3 {
                color: #EF6E26 !important;
            }

            .color4 {
                color: #29B8AC !important;
            }

            .card-panel-num {
                font-family: ds-digitalnormal !important;
            }
        }
    }

    .echart_title {
        width: 100%;
        height: 18.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFangSC-Regular;
        font-size: 16px !important;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        font-weight: 600;
        margin-top: 17px;
    }

    .line_content {
        width: 100%;
        height: 140px;
        margin-top: 5px;
    }

    #myEcharts {
        width: 100%;
        height: 100%;
    }
}

.enter_content {
    width: 100px;
    height: 110%;
    background-color: #4874cb;
    color: #fff;
    margin-left: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
}
</style>