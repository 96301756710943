<template>
    <div class="page">
        <div class="content">

            <div class="row">
                <div>
                    <span class="tag">*</span>
                    商户类型
                </div>
                <div>
                    <van-checkbox-group v-model="merchants_type" direction="horizontal">
                        <van-checkbox name="1" checked-color="#31b158">采购商</van-checkbox>
                        <van-checkbox name="2" checked-color="#31b158">供应商</van-checkbox>
                    </van-checkbox-group>
                </div>
            </div>
            <div class="row">
                <div>
                    <span class="tag">*</span>
                    类型
                </div>
                <div>
                    <van-radio-group v-model="subData.sell_type" direction="horizontal">
                        <van-radio name="1" checked-color="#31b158">个人</van-radio>
                        <van-radio name="2" checked-color="#31b158">个体户</van-radio>
                        <van-radio name="3" checked-color="#31b158">企业</van-radio>
                    </van-radio-group>
                </div>
            </div>
            <div class="row">
                <div>
                    <span class="tag">*</span>
                    商户名称
                </div>
                <div>
                    <input class="input_content" v-model="subData.name" type="text" placeholder="请输入商户名称" />
                </div>
            </div>
            <div class="row">
                <div>
                    <span class="tag">*</span>
                    联系人
                </div>
                <div>
                    <input class="input_content" v-model="subData.linker" type="text" placeholder="请输入联系人" />
                </div>
            </div>
            <div class="row">
                <div>
                    <span class="tag">*</span>
                    联系方式
                </div>
                <div>
                    <input class="input_content" v-model="subData.mobile" maxlength="11" type="tel"
                        placeholder="请输入联系方式" />
                </div>
            </div>
            <div class="row">
                <div>
                    <span class="tag">*</span>
                    身份证号
                </div>
                <div>
                    <input class="input_content" v-model="subData.id_code" maxlength="18" type="text"
                        placeholder="请输入身份证号" />
                </div>
            </div>
        </div>
        <div class="add_btn">
            <div class="btn" @click="handleAddUser">
                快 速 入 驻
            </div>
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {
            subData: {
                merchants_type: "",
                name: "",
                linker: "",
                mobile: "",
                sell_type: "1",
                id_code: ""
            },
            merchants_type: ['1']
        }
    },
    created() {

    },
    mounted() {
        this.subData.mobile = this.getParameterByName("mobile")
    },
    methods: {
        // 解析URL
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            //匹配所有符合条件的，并取最后一个
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'g');
            var results = url.match(regex);
            var tempResults = results != null && results[results.length - 1] != undefined ? results[results.length - 1] : '';
            var finalResults = regex.exec(tempResults);
            if (!finalResults) return "";
            if (!finalResults[2]) return '';
            return decodeURIComponent(finalResults[2].replace(/\+/g, " "));
        },
        async handleAddUser() {
            this.subData.merchants_type = (this.merchants_type).toString()
            if (!this.subData.merchants_type) {
                this.$notify({ type: "warning", message: "请输入商户类型！" });
                return
            }
            if (!this.subData.name) {
                this.$notify({ type: "warning", message: "请输入商户名称！" });
                return
            }
            if (!this.subData.linker) {
                this.$notify({ type: "warning", message: "请输入联系人姓名！" });
                return
            }
            if (!this.subData.mobile) {
                this.$notify({ type: "warning", message: "请输入联系方式！" });
                return
            }
            if (!this.subData.id_code) {
                this.$notify({ type: "warning", message: "请输入身份证号码！" });
                return
            }
            if (!(/^1(3|4|5|7|8)\d{9}$/.test(this.subData.mobile))) {
                this.$notify({ type: "warning", message: "请输入正确的手机号！" });
                return false;
            }
            if (!(/^[1-9]{1}[0-9]{14}$|^[1-9]{1}[0-9]{16}([0-9]|[xX])$/.test(this.subData.id_code))) {
                this.$notify({ type: "warning", message: "身份证号有误，请重新输入！" });
                return false;
            }
            const { data: res } = await this.$https.post('/mobileh5/mini-login/quick-settled', this.subData)
            var that = this
            if (res.resultCode == "1") {
                that.$notify({ type: "success", message: res.message });
                setTimeout(function () {
                    that.$router.push({
                        path: '/login',
                        query: {
                            mobile: that.subData.mobile
                        }
                    })
                }, 1e3);
            } else {
                that.$notify({ type: "danger", message: res.message });
            }
        }
    },
}
</script>

<style lang="less" scoped>
[v-cloak] {
    display: none
}

.page {
    background-color: #fff;
    user-select: none;
}

.page {
    background-color: #f7f8f9;
    height: 100vh;
    width: 100vw;
}

.content {
    width: 100%;
    padding-top: 20px;
    background-color: #fff;
    padding-bottom: 20px;
}

.row {
    width: 100%;
    height: 44px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
}

.row>div {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid #eee;
}

.row>div:nth-child(1) {
    flex: 1;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-left: 15px;
}

.row>div:nth-child(2) {
    flex: 3;
    display: flex;
    align-items: center;
    font-size: 15px;
    box-sizing: border-box;
    padding-left: 15px;
}

.tag {
    color: red;
    margin-right: 2px;
}

.input_content {
    width: 95%;
    height: 100%;
    background-color: #fff;
    padding: 0 5px;
    box-sizing: border-box;
    outline: none;
    font-size: 15px;
    border: none;
}

.add_btn {
    width: 100%;
    height: 45px;
    margin-top: 200px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    background-color: #31b158;
    border-radius: 22.5px;
}
</style>