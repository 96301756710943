import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vant.js'

import axios from 'axios'
Vue.prototype.$https = axios
axios.defaults.baseURL = 'https://api-ltnp.dbsddw.com:8019'

Vue.config.productionTip = false

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
