<template>
    <div class="page">
        <div class="content">
            <van-cell-group>
                <van-field type="number" v-model="subData.recharge_amount" required clearable label="充值金额(元)"
                    input-align="right" placeholder="请输入充值金额" />
            </van-cell-group>
            <van-cell title="充值时间" required v-model="subData.recharge_time" :is-link="true" @click="show = true" />
            <van-calendar v-model="show" @confirm="onConfirm" :min-date="minDate" color="#31b158" :max-date="maxDate" />
            <div class="pic_content">
                <div class="title">
                    <span>*</span>
                    充值凭证
                </div>
                <van-uploader v-model="fileList" :after-read="afterRead" @delete="delFile" :max-count="9" />
            </div>
        </div>
        <div class="btn_content">
            <div class="btn" @click="handleSubmit">
                提 交
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            subData: {
                recharge_amount: "",
                recharge_time: "",
                url: ""
            },
            show: false,
            minDate: new Date(2023, 0, 1), //可选最早时间
            maxDate: new Date(),
            fileList: [],
            imgList: [],
            access_token: "",
            mobile: ""
        }
    },
    created() {
        this.mobile = this.getParameterByName("mobile")
        var that = this
        if (!window.sessionStorage.getItem("access_token")) {
            this.$dialog.alert({
                title: "提醒",
                message: '您暂未登录！是否前往登录?',
                showConfirmButton: true,
                showCancelButton: true
            }).then(() => {
                that.$router.push({
                    path: '/Login',
                    query: {
                        mobile: that.mobile
                    }
                });
            }).catch(() => {
                that.$router.push({
                    path: '/index',
                    query: {
                        mobile: that.mobile
                    }
                });
            });
        } else {
            this.access_token = window.sessionStorage.getItem("access_token")
        }
    },
    mounted() {
        this.pushHistory()
        //  监听popstate事件
        window.addEventListener('popstate', this.listenPopstate);
    },

    beforeDestroy() {
        window.removeEventListener('popstate', this.listenPopstate);
    },
    methods: {
        listenPopstate() {
            if (this.$route.path === '/PurchaseRecharge') {
                this.$router.push({
                    path: '/index',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
            //  跳转小程序页面相关逻辑
        },
        pushHistory() {
            window.history.pushState(null, null, null)
        },
        // 解析URL
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            //匹配所有符合条件的，并取最后一个
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'g');
            var results = url.match(regex);
            var tempResults = results != null && results[results.length - 1] != undefined ? results[results.length - 1] : '';
            var finalResults = regex.exec(tempResults);
            if (!finalResults) return "";
            if (!finalResults[2]) return '';
            return decodeURIComponent(finalResults[2].replace(/\+/g, " "));
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            return `${year}-${month}-${day}`;
        },
        onConfirm(date) {
            this.show = false;
            this.subData.recharge_time = this.formatDate(date);
        },
        async afterRead(file) {
            const { data: res } = await this.$https.post('/mobileh5/mini-program/upload?access_token=' + window.sessionStorage.getItem("access_token"), { url: file.content, type: file.file.type })
            if (res.resultCode == "1") {
                this.imgList.push(res.resultInfo.url)
            }
        },
        delFile(e, index) {
            this.imgList.splice(index, 1)
        },
        async handleSubmit() {
            this.subData.url = (this.imgList).toString();
            if (!this.subData.recharge_amount) {
                this.$toast({ type: 'fail', message: "请先输入充值金额！" });
                return;
            }
            if (!this.subData.recharge_time) {
                this.$toast({ type: 'fail', message: "请先选择充值时间！" });
                return;
            }
            if (!this.subData.url) {
                this.$toast({ type: 'fail', message: "请先上传充值凭证！" });
                return;
            }
            const { data: res } = await this.$https.post('/mobileh5/order/record-create?access_token=' + window.sessionStorage.getItem("access_token"), this.subData)
            if (res.resultCode == "1") {
                this.$toast({ type: 'success', message: res.message })
                this.subData = {
                    recharge_amount: "",
                    recharge_time: "",
                    url: ""
                }
                this.fileList = []
                this.imgList = []
            } else {
                this.$toast({ type: 'fail', message: res.message })
            }
        },
    },
}
</script>

<style lang="less" scoped>
[v-cloak] {
    display: none
}

.page {
    background-color: #f7f8f9;
    user-select: none;
    box-sizing: border-box;
}

.content {
    width: 100%;
    overflow-y: auto;
    margin-top: 5px;
}

.pic_content {
    padding: 8px;
    background-color: #fff;
}

.title {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;

    span {
        color: red;
    }
}

.btn_content {
    width: 100%;
    height: 48px;
    margin-top: 160px;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
        width: 96%;
        height: 100%;
        border-radius: 24px;
        background-color: #31b158;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>