<template>
    <div class="page">
        <div class="content">
            <div class="time_content">
                <div class="date_content" @click="show = true">
                    <span>请选择日期</span>
                    <span>{{ listQuery.start || "暂无" }}</span>
                </div>
                <div>
                    <img src="../assets/bk.png" alt="">
                    <span>{{ day }} 天</span>
                </div>
                <div class="date_content" @click="show = true">
                    <span>请选择日期</span>
                    <span>{{ listQuery.end || "暂无" }}</span>
                </div>
            </div>
            <div class="tab_content">
                <div class="tab_title">
                    数据统计
                </div>
                <div class="tab">
                    <div class="row">
                        <div>
                            <span>干货订单重量</span>
                            <span>
                                <span class="num">{{ Number(stat.ganhuo_weight_num).toFixed(2) || 0.00 }}</span>
                                kg
                            </span>
                        </div>
                        <div>
                            <span>干货订单金额</span>
                            <span>
                                <span class="num">{{ Number(stat.ganhuo_amount).toFixed(2) || 0.00 }}</span>
                                元
                            </span>
                        </div>
                        <div>
                            <span>干货订单笔数</span>
                            <span>
                                <span class="num">{{ Number(stat.ganhuo_order_num).toFixed(0) || 0 }}</span>
                                单
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div>
                            <span>鲜货订单重量</span>
                            <span>
                                <span class="num">{{ Number(stat.xianhuo_weight_num).toFixed(2) || 0.00 }}</span>
                                kg
                            </span>
                        </div>
                        <div>
                            <span>鲜货订单金额</span>
                            <span>
                                <span class="num">{{ Number(stat.xianhuo_amount).toFixed(2) || 0.00 }}</span>
                                元
                            </span>
                        </div>
                        <div>
                            <span>鲜货订单笔数</span>
                            <span>
                                <span class="num">{{ Number(stat.xianhuo_order_num).toFixed(0) || 0 }}</span>
                                单
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="line_content">
                <div class="tab_title">
                    <img src="../assets/zhexian.png" alt="">
                    数据统计折线图
                </div>
                <div id="myEcharts" v-if="the_date.length > 0"></div>
                <div v-else><van-empty description="当前日期暂无统计数据！" /></div>
            </div>
        </div>
        <van-calendar v-model="show" type="range" :allow-same-day="true" :min-date="minDate" :max-date="maxDate"
            color="#31b158" @confirm="onConfirm" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            listQuery: {
                start: "",
                end: "",
                role_type: ""
            },
            day: "1",
            minDate: new Date(2023, 0, 1), //可选最早时间
            maxDate: new Date(),
            stat: "",
            amount: [],
            the_date: [],
        }
    },
    created() {
        if (window.sessionStorage.getItem("role_type")) {
            this.listQuery.role_type = window.sessionStorage.getItem("role_type")
        }
        this.mobile = this.getParameterByName("mobile")
        var that = this
        if (!window.sessionStorage.getItem("access_token")) {
            this.$dialog.alert({
                title: "提醒",
                message: '您暂未登录！是否前往登录?',
                showConfirmButton: true,
                showCancelButton: true
            }).then(() => {
                that.$router.push({
                    path: '/Login',
                    query: {
                        mobile: that.mobile
                    }
                });
            }).catch(() => {
                that.$router.push({
                    path: '/index',
                    query: {
                        mobile: that.mobile
                    }
                });
            });
        } else {
            this.access_token = window.sessionStorage.getItem("access_token")
            this.getList()
        }

    },
    mounted() {
        this.pushHistory()
        //  监听popstate事件
        window.addEventListener('popstate', this.listenPopstate);
    },

    beforeDestroy() {
        window.removeEventListener('popstate', this.listenPopstate);
    },
    methods: {
        listenPopstate() {
            if (this.$route.path === '/Statistics') {
                this.$router.push({
                    path: '/index',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
            //  跳转小程序页面相关逻辑
        },
        pushHistory() {
            window.history.pushState(null, null, null)
        },
        // 解析URL
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            //匹配所有符合条件的，并取最后一个
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'g');
            var results = url.match(regex);
            var tempResults = results != null && results[results.length - 1] != undefined ? results[results.length - 1] : '';
            var finalResults = regex.exec(tempResults);
            if (!finalResults) return "";
            if (!finalResults[2]) return '';
            return decodeURIComponent(finalResults[2].replace(/\+/g, " "));
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            return `${year}-${month}-${day}`;
        },
        onConfirm(date) {
            const [start, end] = date;
            this.show = false;
            this.day = (new Date(date[1]).getTime() - new Date(date[0]).getTime()) / (1 * 1000 * 60 * 60 * 24) + 1
            this.listQuery.start = this.formatDate(start);
            this.listQuery.end = this.formatDate(end);
            this.getList()
        },
        async getList() {
            const { data: res } = await this.$https.post('/mobileh5/mini-program/get-stat-data?access_token=' + window.sessionStorage.getItem("access_token"), this.listQuery)
            if (res.resultCode == "1") {
                this.amount = res.resultInfo.chart.amount
                this.the_date = res.resultInfo.chart.the_date
                this.stat = res.resultInfo.stat
                if (this.the_date.length > 0) {
                    this.$nextTick(() => {
                        this.initEcharts()
                    });
                }
            }
        },
        // 折线图
        initEcharts() {
            // 1，挂载节点
            let myEcharts = this.$echarts.init(document.getElementById('myEcharts'));
            // 2，设置图表的属性
            let options = {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.the_date,
                    axisLabel: {
                        textStyle: {
                            fontSize: 12  // X轴字体大小
                        }
                    }
                },
                grid: {  //方法 1
                    left: 15,
                    right: 20,
                    bottom: 15,
                    top: 30,
                    containLabel: true
                },

                tooltip: {
                    trigger: "axis",
                    axisLabel: {
                        textStyle: {
                            fontSize: 12  // X轴字体大小
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            fontSize: 12  // X轴字体大小
                        }
                    }
                },
                series: [{
                    name: '金额',
                    type: 'line',
                    smooth: true,
                    itemStyle: {
                        color: '#dce7fb',
                        normal: {
                            lineStyle: {        // 系列级个性化折线样式 
                                width: 2,
                                type: 'solid',
                            }
                        },
                        emphasis: {
                            color: '#dce7fb',
                            lineStyle: {        // 系列级个性化折线样式 
                                width: 2,
                                type: 'dotted',
                            }
                        }
                    },// 线条样式
                    data: this.amount,
                    areaStyle: {
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: "#48C78E" },
                            { offset: 0.5, color: "rgba(182,229,187,0.3)" },
                            { offset: 1, color: "rgba(182,229,187,0.1)" },
                        ]),
                    },


                }]
            };

            // 3，使用上面的options属性 正式生成图表
            myEcharts.setOption(options);
        },
    },
}
</script>

<style lang="less" scoped>
[v-cloak] {
    display: none
}

.page {
    background-color: #f7f8f9;
    user-select: none;
    box-sizing: border-box;
}

.title {
    width: 100%;
    display: flex;
    height: 44px;
    background-color: #31b158;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;

    .back {
        width: 44px;
        height: 100%;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
        }
    }
}

.content {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.time_content {
    width: 100%;
    height: 52px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;

    >div {
        flex: 1;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    >div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 12px;
        color: #9ea5b8;

        img {
            width: 100%;
            height: 23px;
        }

        span {
            position: absolute;
        }
    }
}

.date_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 15px;

    >span:nth-child(1) {
        color: #9ea5b8;
        font-size: 13px;
    }
}

.tab_content {
    width: 94%;
    height: 200px;
    box-sizing: border-box;
    margin-top: 12px;
    background-color: #fff;
    margin-left: 3%;
    border-radius: 12px;

    .tab_title {
        width: 100%;
        box-sizing: border-box;
        height: 40px;
        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
        padding-left: 16px;
    }

    .tab {
        width: 100%;
        height: 160px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 14px;
        padding-top: 0;

        .row {
            width: 100%;
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >div {
                width: 32%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                box-sizing: border-box;
                background-color: #f7f8f9;
                border-radius: 8px;
                padding: 6px 10px;

                >span {
                    color: #323b4d;
                    font-size: 12px;
                }

                .num {
                    font-size: 14px;
                }
            }

        }

        .row:nth-child(1) {
            margin-bottom: 10px;
        }
    }
}

.line_content {
    width: 94%;
    height: 340px;
    box-sizing: border-box;
    margin-top: 12px;
    background-color: #fff;
    margin-left: 3%;
    border-radius: 12px;

    .tab_title {
        width: 100%;
        box-sizing: border-box;
        height: 40px;
        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
        padding-left: 16px;
        display: flex;
        align-items: center;

        img {
            width: 40px;
            height: 18px;
            margin-right: 12px;
        }
    }

    #myEcharts {
        width: 100%;
        height: calc(100% - 40px);
        box-sizing: border-box;
    }

}
</style>