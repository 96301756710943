<template>
    <div class="page">
        <div class="content">
            <div class="time_content">
                <div class="date_content" @click="show = true">
                    <span>请选择日期</span>
                    <span>{{ listQuery.start || "暂无" }}</span>
                </div>
                <div>
                    <img src="../assets/bk.png" alt="">
                    <span>{{ day }} 天</span>
                </div>
                <div class="date_content" @click="show = true">
                    <span>请选择日期</span>
                    <span>{{ listQuery.end || "暂无" }}</span>
                </div>
            </div>
            <div class="list_conetnt" v-if="list.length > 0">
                <van-checkbox-group v-model="ids">
                    <div class="item" v-for="(item, index) in list" :key="index">
                        <div class="row">
                            <div>订单编号: {{ item.order_sn }}</div>
                            <div>
                                <van-checkbox :name="item.id"></van-checkbox>
                            </div>
                        </div>
                        <div class="van-hairline--bottom"></div>
                        <div class="row">
                            <div>供货商:</div>
                            <div class="num">
                                {{ item.seller_name }}
                            </div>
                        </div>
                        <div class="row">
                            <div>采购商:</div>
                            <div class="num">
                                {{ item.buyer_name }}
                            </div>
                        </div>
                        <div class="row">
                            <div>订单金额(元):</div>
                            <div class="num">
                                {{ item.pay_amount }}
                            </div>
                        </div>
                        <div class="row">
                            <div>订单时间:</div>
                            <div class="num">
                                {{ item.create_at }}
                            </div>
                        </div>
                    </div>
                </van-checkbox-group>
            </div>
            <div v-else>
                <van-empty description="暂无开票订单信息！" />
            </div>
            <div class="btn_content">
                <div class="btn" @click="onSubmit">提 交</div>
            </div>
        </div>
        <van-calendar v-model="show" type="range" :allow-same-day="true" :min-date="minDate" :max-date="maxDate"
            color="#31b158" @confirm="onConfirm" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            listQuery: {
                start: "",
                end: "",
            },
            day: "1",
            minDate: new Date(2023, 0, 1), //可选最早时间
            maxDate: new Date(),
            mobile: "",
            list: [],
            ids: [],
            access_token: ""
        }
    },
    created() {
        if (this.getParameterByName("ids")) {
            this.ids = JSON.parse(this.getParameterByName("ids"))
        }
        this.getList()
    },

    mounted() {
        this.pushHistory()
        //  监听popstate事件
        window.addEventListener('popstate', this.listenPopstate);
    },

    beforeDestroy() {
        window.removeEventListener('popstate', this.listenPopstate);
    },
    methods: {
        listenPopstate() {
            if (this.$route.path === '/KaipiaoOrder') {
                this.$router.push({
                    path: '/KaipiaoApply',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
            //  跳转小程序页面相关逻辑
        },
        pushHistory() {
            window.history.pushState(null, null, null)
        },
        // 解析URL
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            //匹配所有符合条件的，并取最后一个
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'g');
            var results = url.match(regex);
            var tempResults = results != null && results[results.length - 1] != undefined ? results[results.length - 1] : '';
            var finalResults = regex.exec(tempResults);
            if (!finalResults) return "";
            if (!finalResults[2]) return '';
            return decodeURIComponent(finalResults[2].replace(/\+/g, " "));
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            return `${year}-${month}-${day}`;
        },
        onConfirm(date) {
            const [start, end] = date;
            this.show = false;
            this.day = (new Date(date[1]).getTime() - new Date(date[0]).getTime()) / (1 * 1000 * 60 * 60 * 24) + 1
            this.listQuery.start = this.formatDate(start);
            this.listQuery.end = this.formatDate(end);
            this.getList()
        },
        async getList() {
            const { data: res } = await this.$https.post('/mobileh5/order/fapiao-order-list?access_token=' + window.sessionStorage.getItem("access_token"), this.listQuery, { timeout: 2000 })
            if (res.resultCode == "1") {
                this.list = res.resultInfo
            }
        },
        onSubmit() {
            if (this.ids.length == 0) {
                this.$toast({ type: 'fail', message: "请先选择订单！" });
            } else {
                this.$router.push({
                    path: '/KaipiaoApply',
                    query: {
                        ids: JSON.stringify(this.ids)
                    }
                })
            }

        }
    },
}
</script>

<style lang="less" scoped>
[v-cloak] {
    display: none
}

.page {
    background-color: #f1f4f9;
    user-select: none;
    width: 100%;
    height: 100vh;
}

.content {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.time_content {
    width: 100%;
    height: 52px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;

    >div {
        flex: 1;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    >div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 12px;
        color: #9ea5b8;

        img {
            width: 100%;
            height: 23px;
        }

        span {
            position: absolute;
        }
    }
}

.date_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 15px;

    >span:nth-child(1) {
        color: #9ea5b8;
        font-size: 13px;
    }
}

.list_conetnt {
    width: 100%;
    height: calc(100vh - 60px - 55px);
    margin-top: 8px;
    box-sizing: border-box;
    padding: 0 10px;

    .item {
        background-color: #fff;
        width: 100%;
        // height: 160px;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 8px;
        margin-bottom: 10px;

        .row {
            width: 100%;
            height: 36px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.num {
    font-weight: 600;
    font-style: 18px;
    color: #31b158;
}

.btn_content {
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
        width: 95%;
        height: 45px;
        background-color: #31b158;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 22.5px;
    }
}
</style>