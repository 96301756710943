<template>
    <div class="page">
        <div class="top_content">
            <div class="logo"></div>
        </div>
        <div class="login_box">
            <div class="row">
                <div class="row_content">
                    <img src="../assets/icon_shoujihao.png" alt="">
                    <div style="width: 80px;">手机号:</div>
                    <input type="tel" v-model="subData.mobile" maxlength="11" placeholder="请输入">
                </div>
            </div>
            <div class="row">
                <div class="row_content">
                    <img src="../assets/icon_mima.png" alt="">
                    <div style="width: 80px;">验证码:</div>
                    <input type="text" v-model="subData.code" placeholder="请输入">
                </div>
            </div>
            <div class="row">
                <div class="row_content">
                    <img src="../assets/icon_mima.png" alt="">
                    <div>图片验证码:</div>
                    <img class="code_url" v-if="url != ''" :src="url" alt="" @click="getCodeImg">
                    <div class="code_btn" v-else @click="getCode">获取验证码</div>
                </div>
            </div>
        </div>
        <!-- <div class="check_box">
            <van-checkbox v-model="checked" icon-size="13px"></van-checkbox>
            <div class="check_box1">已阅读并同意</div>
            <div class="check_box2">《用户协议与隐私政策》</div>
        </div> -->
        <div class="btn_content">
            <div class="btn" @click="onSubmit">登 录</div>
        </div>
    </div>
</template>

<script>
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            subData: {
                mobile: "",
                code: ""
            },
            url: "",
            checked: false
        }
    },
    created() {

    },
    mounted() {
        this.subData.mobile = this.getParameterByName("mobile")
        window.sessionStorage.setItem("mobile", this.subData.mobile)
        this.getCodeImg()
        this.pushHistory()
        //  监听popstate事件
        window.addEventListener('popstate', this.listenPopstate);
    },

    beforeDestroy() {
        window.removeEventListener('popstate', this.listenPopstate);
    },
    methods: {
        listenPopstate() {
            if (this.$route.path === '/Login') {
                wx.miniProgram.switchTab({ url: "/pages/other/index" });
            }
            //  跳转小程序页面相关逻辑
        },
        pushHistory() {
            window.history.pushState(null, null, null)
        },
        // 解析URL
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            //匹配所有符合条件的，并取最后一个
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'g');
            var results = url.match(regex);
            var tempResults = results != null && results[results.length - 1] != undefined ? results[results.length - 1] : '';
            var finalResults = regex.exec(tempResults);
            if (!finalResults) return "";
            if (!finalResults[2]) return '';
            return decodeURIComponent(finalResults[2].replace(/\+/g, " "));
        },
        async onSubmit() {
            var that = this
            const { data: res } = await this.$https.post('/mobileh5/mini-login/login', this.subData, { timeout: 2000 })
            if (res.resultCode == "1") {
                window.sessionStorage.setItem("access_token", res.resultInfo.access_token)
                window.sessionStorage.setItem("merchants_type", res.resultInfo.merchants_type)
                window.sessionStorage.setItem("role_type", res.resultInfo.role_type)
                that.$router.push({
                    path: '/Index',
                    query: {
                        mobile: that.subData.mobile
                    }
                });
            } else if (res.resultCode == "0") {
                that.getCodeImg()
                this.$dialog.alert({
                    title: "提醒",
                    message: '您暂未入驻！是否前往快速入驻？',
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(() => {
                    that.$router.push({
                        path: '/MerchantCreate',
                        query: {
                            mobile: that.subData.mobile
                        }
                    });
                    // on confirm
                }).catch(() => {
                    console.log(2222);
                    // on cancel
                });
            } else if (res.resultCode == "-1") {
                that.getCodeImg()
                this.$notify({ type: "danger", message: res.message });
                this.subData.code = ''
            }
        },
        getCode() {
            if (!this.subData.mobile) {
                this.$toast({ type: 'fail', message: "请先输入手机号码！" });
                return;
            }
            this.getCodeImg()
        },
        async getCodeImg() {
            const { data: res } = await this.$https.get('/mobileh5/mini-login/get-verify?mobile=' + this.subData.mobile, { timeout: 2000 })
            if (res.resultCode == "1") {
                this.url = res.resultInfo
            }
            return
        }
    },
}
</script>

<style lang="less" scoped>
[v-cloak] {
    display: none
}

.page {
    background-color: #ffffff;
    user-select: none;
}

.top_content {
    width: 100%;
    height: 165.23px;
    box-sizing: border-box;
    background: url(../assets/top_bgc.png) center no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
}

.logo {
    width: 250px;
    height: 120px;
    background-color: #fff;
    background: url(../assets/logo.png) center no-repeat;
    background-size: 100% 100%;
    margin-top: calc(238.91px / 2);
}

.login_box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: calc(221.54px / 2);
}

.row {
    width: 310px;
    height: 59px;
    box-sizing: border-box;
    background: url(../assets/input_bgc.png) center no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    padding: 0 24.5px;

    .row_content {
        opacity: 0.64;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #4E4E4E;
        letter-spacing: 0;
        font-weight: 400;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        img {
            width: 13px;
            height: 13px;
            margin-right: 5.5px;
        }

        input {
            outline: none;
            border: none;
            box-sizing: border-box;
            height: 100%;
        }
    }
}

.code_url {
    width: 90px !important;
    height: 31.5px !important;
    margin-right: 0 !important;
    margin-left: 10px;
}

.code_btn {
    width: 90px !important;
    height: 31.5px !important;
    margin-right: 0 !important;
    margin-left: 10px;
    background-color: #31b158;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.check_box {
    width: 100%;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    // padding-left: 69px;
    margin-top: 91px;

    .check_box1 {
        font-family: PingFangSC-Regular;
        font-size: 13px;
        color: #4E4E4E;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: 400;
        margin-left: 6px;
    }

    .check_box2 {
        font-family: PingFangSC-Regular;
        font-size: 13px;
        color: #288EFD;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: 400;
    }
}

.btn_content {
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    // margin-top: 16px;
    margin-top: 117px;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
        width: 306px;
        height: 100%;
        background-image: linear-gradient(180deg, #2461FA 0%, #20A8FF 100%);
        box-shadow: 0 5px 5px .5px rgba(34, 97, 253, 0.31);
        border-radius: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 16px;
        font-weight: 500;
    }
}
</style>