import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Statistics from '@/views/Statistics.vue'
import Subsidy from '@/views/Subsidy.vue'
import Login from '@/views/Login.vue'
import MerchantCreate from "@/views/MerchantCreate.vue"
import EquipmentReceive from '@/views/EquipmentReceive.vue'
import PurchaseRecharge from '@/views/PurchaseRecharge.vue'
import RechargeList from '@/views/RechargeList.vue'
import KaipiaoApply from '@/views/KaipiaoApply.vue'
import OrderConfirm from '@/views/OrderConfirm.vue'
import OrderList from '@/views/OrderList.vue'
import KaipiaoOrder from '@/views/KaipiaoOrder.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index' },
  { path: '/login', name: 'login', component: Login, meta: { title: '用户登录' } },
  { path: '/merchantCreate', name: 'merchantCreate', component: MerchantCreate, meta: { title: '商户入驻' } },
  { path: '/index', name: 'index', component: Index, meta: { title: '园区交易' } },
  { path: '/statistics', name: 'statistics', component: Statistics, meta: { title: '收入统计' } },
  { path: '/subsidy', name: 'subsidy', component: Subsidy, meta: { title: '商户补贴' } },
  { path: '/equipmentReceive', name: 'equipmentReceive', component: EquipmentReceive, meta: { title: '设备领用' } },
  { path: '/purchaseRecharge', name: 'purchaseRecharge', component: PurchaseRecharge, meta: { title: '采购充值' } },
  { path: '/rechargeList', name: 'rechargeList', component: RechargeList, meta: { title: '充值记录' } },
  { path: '/kaipiaoApply', name: 'kaipiaoApply', component: KaipiaoApply, meta: { title: '开票申请' } },
  { path: '/orderConfirm', name: 'orderConfirm', component: OrderConfirm, meta: { title: '确认订单' } },
  { path: '/orderList', name: 'orderList', component: OrderList, meta: { title: '订单列表' } },
  { path: '/kaipiaoOrder', name: 'kaipiaoOrder', component: KaipiaoOrder, meta: { title: '开票订单' } },
]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 如果路由元信息中有title属性，则将其设置为页面标题
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router
