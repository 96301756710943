<template>
    <div class="page">
        <div class="content">
            <div class="time_content">
                <div class="date_content" @click="show = true">
                    <span>请选择日期</span>
                    <span>{{ listQuery.start || "暂无" }}</span>
                </div>
                <div>
                    <img src="../assets/bk.png" alt="">
                    <span>{{ day }} 天</span>
                </div>
                <div class="date_content" @click="show = true">
                    <span>请选择日期</span>
                    <span>{{ listQuery.end || "暂无" }}</span>
                </div>
            </div>
            <div class="list_content" v-if="list.length > 0">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="row">
                        <span>订单编号：</span>
                        <span>{{ item.order_sn }}</span>
                    </div>
                    <div class="row">
                        <span>订单金额(元)</span>
                        <span>{{ item.order_amount }}</span>
                    </div>
                    <div class="row">
                        <span>补贴金额(元)</span>
                        <span class="butie_amount">{{ item.butie_amount }}</span>
                    </div>
                    <div class="row">
                        <span>时间</span>
                        <span>{{ item.create_at }}</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <van-empty description="暂无补贴记录！" />
            </div>
        </div>
        <van-calendar v-model="show" type="range" :allow-same-day="true" :min-date="minDate" :max-date="maxDate"
            color="#31b158" @confirm="onConfirm" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            listQuery: {
                start: "",
                end: ""
            },
            day: "1",
            minDate: new Date(2023, 0, 1), //可选最早时间
            maxDate: new Date(),
            list: []
        }
    },
    created() {
        this.mobile = this.getParameterByName("mobile")
        var that = this
        if (!window.sessionStorage.getItem("access_token")) {
            this.$dialog.alert({
                title: "提醒",
                message: '您暂未登录！是否前往登录?',
                showConfirmButton: true,
                showCancelButton: true
            }).then(() => {
                that.$router.push({
                    path: '/Login',
                    query: {
                        mobile: that.mobile
                    }
                });
            }).catch(() => {
                that.$router.push({
                    path: '/index',
                    query: {
                        mobile: that.mobile
                    }
                });
            });
        } else {
            this.access_token = window.sessionStorage.getItem("access_token")
            this.getList()
        }
    },
    mounted() {
        this.pushHistory()
        //  监听popstate事件
        window.addEventListener('popstate', this.listenPopstate);
    },

    beforeDestroy() {
        window.removeEventListener('popstate', this.listenPopstate);
    },
    methods: {
        listenPopstate() {
            if (this.$route.path === '/Subsidy') {
                this.$router.push({
                    path: '/index',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
            //  跳转小程序页面相关逻辑
        },
        pushHistory() {
            window.history.pushState(null, null, null)
        },
        // 解析URL
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            //匹配所有符合条件的，并取最后一个
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'g');
            var results = url.match(regex);
            var tempResults = results != null && results[results.length - 1] != undefined ? results[results.length - 1] : '';
            var finalResults = regex.exec(tempResults);
            if (!finalResults) return "";
            if (!finalResults[2]) return '';
            return decodeURIComponent(finalResults[2].replace(/\+/g, " "));
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            return `${year}-${month}-${day}`;
        },
        onConfirm(date) {
            const [start, end] = date;
            this.show = false;
            this.day = (new Date(date[1]).getTime() - new Date(date[0]).getTime()) / (1 * 1000 * 60 * 60 * 24) + 1
            this.listQuery.start = this.formatDate(start);
            this.listQuery.end = this.formatDate(end);
            this.getList()
        },
        async getList() {
            const { data: res } = await this.$https.post('/mobileh5/mini-program/get-butie-list?access_token=' + window.sessionStorage.getItem("access_token"), this.listQuery)
            if (res.resultCode == "1") {
                this.list = res.resultInfo
            }
        }
    },
}
</script>

<style lang="less" scoped>
[v-cloak] {
    display: none
}

.page {
    background-color: #f7f8f9;
    user-select: none;
    box-sizing: border-box;
}

.title {
    width: 100%;
    display: flex;
    height: 44px;
    background-color: #31b158;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;

    .back {
        width: 44px;
        height: 100%;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
        }
    }
}

.content {
    // margin-top: 44px;
    // height: calc(100vh - 44px);
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.time_content {
    width: 100%;
    height: 52px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;

    >div {
        flex: 1;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    >div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 12px;
        color: #9ea5b8;

        img {
            width: 100%;
            height: 23px;
        }

        span {
            position: absolute;
        }
    }
}

.date_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 15px;

    >span:nth-child(1) {
        color: #9ea5b8;
        font-size: 13px;
    }
}

.list_content {
    width: 100%;
    margin-top: 8px;
    // background-color: pink;
    height: calc(100vh - 108px);
    box-sizing: border-box;
    padding: 0 12px;

    .item {
        width: 100%;
        height: auto;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 4px 6px;
        margin-bottom: 12px;

        .row {
            width: 100%;
            height: 30px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eee;
        }

        .row:nth-last-child(1) {
            border-bottom: 0;
        }
    }
}

.butie_amount {
    font-weight: 600;
    color: red;
}
</style>