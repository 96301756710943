<template>
    <div class="page">
        <div class="content">
            <div class="search_content">
                <div class="search">
                    <van-search v-model="search" placeholder="请输入搜索关键词" />
                </div>
                <div class="scan_img" @click="startScan">
                    <img src="../assets/saoma.png" alt="">
                </div>
            </div>
            <div class="list_conetnt" v-if="list.length > 0">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="row">
                        <div>订单编号: </div>
                        <div>
                            {{ item.order_sn }}
                        </div>
                    </div>
                    <div class="row">
                        <div>供应商:</div>
                        <div>
                            {{ item.seller_name }}
                        </div>
                    </div>
                    <div class="row">
                        <div>订单时间:</div>
                        <div>
                            {{ item.create_at }}
                        </div>
                    </div>
                    <div class="row">
                        <div>订单金额(元):</div>
                        <div>
                            {{ item.pay_amount }}
                        </div>
                    </div>
                    <div class="row">
                        <div>订单状态:</div>
                        <div>
                            <van-tag size="large" type="warning">待签署</van-tag>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <van-empty description="暂无订单！" />
            </div>
        </div>
        <!-- 扫一扫 -->
        <div class="scan" v-if="isScanning">
            <div class="scan-box">
                <div id="scanning" width="800px"></div>
            </div>
            <span class="close-scan" @click="stopScan"><svg t="1681382339822" class="icon" viewBox="0 0 1024 1024"
                    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2626" width="200" height="200">
                    <path
                        d="M512 960c-247.039484 0-448-200.960516-448-448S264.960516 64 512 64 960 264.960516 960 512 759.039484 960 512 960zM512 128.287273c-211.584464 0-383.712727 172.128262-383.712727 383.712727 0 211.551781 172.128262 383.712727 383.712727 383.712727 211.551781 0 383.712727-172.159226 383.712727-383.712727C895.712727 300.415536 723.551781 128.287273 512 128.287273z"
                        fill="#ffffff" p-id="2627"></path>
                    <path
                        d="M557.05545 513.376159l138.367639-136.864185c12.576374-12.416396 12.672705-32.671738 0.25631-45.248112s-32.704421-12.672705-45.248112-0.25631l-138.560301 137.024163-136.447897-136.864185c-12.512727-12.512727-32.735385-12.576374-45.248112-0.063647-12.512727 12.480043-12.54369 32.735385-0.063647 45.248112l136.255235 136.671523-137.376804 135.904314c-12.576374 12.447359-12.672705 32.671738-0.25631 45.248112 6.271845 6.335493 14.496116 9.504099 22.751351 9.504099 8.12794 0 16.25588-3.103239 22.496761-9.247789l137.567746-136.064292 138.687596 139.136568c6.240882 6.271845 14.432469 9.407768 22.65674 9.407768 8.191587 0 16.352211-3.135923 22.591372-9.34412 12.512727-12.480043 12.54369-32.704421 0.063647-45.248112L557.05545 513.376159z"
                        fill="#ffffff" p-id="2628"></path>
                </svg></span>
        </div>
    </div>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode';
export default {
    data() {
        return {
            search: "",
            isScanning: false, // 是否正在扫一扫
            subData: {
                device_sn: "",
                order_id: ""
            },
            list: [],
            mobile: "",
            access_token: ""
        }
    },
    created() {
        this.mobile = this.getParameterByName("mobile")
        var that = this
        if (!window.sessionStorage.getItem("access_token")) {
            this.$dialog.alert({
                title: "提醒",
                message: '您暂未登录！是否前往登录?',
                showConfirmButton: true,
                showCancelButton: true
            }).then(() => {
                that.$router.push({
                    path: '/Login',
                    query: {
                        mobile: that.mobile
                    }
                });
            }).catch(() => {
                that.$router.push({
                    path: '/index',
                    query: {
                        mobile: that.mobile
                    }
                });
            });
        } else {
            this.access_token = window.sessionStorage.getItem("access_token")
            this.getList()
        }
    },
    mounted() {
        this.pushHistory()
        //  监听popstate事件
        window.addEventListener('popstate', this.listenPopstate);
    },

    beforeDestroy() {
        window.removeEventListener('popstate', this.listenPopstate);
    },
    methods: {
        listenPopstate() {
            if (this.$route.path === '/OrderConfirm') {
                this.$router.push({
                    path: '/index',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
            //  跳转小程序页面相关逻辑
        },
        pushHistory() {
            window.history.pushState(null, null, null)
        },
        // 解析URL
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            //匹配所有符合条件的，并取最后一个
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'g');
            var results = url.match(regex);
            var tempResults = results != null && results[results.length - 1] != undefined ? results[results.length - 1] : '';
            var finalResults = regex.exec(tempResults);
            if (!finalResults) return "";
            if (!finalResults[2]) return '';
            return decodeURIComponent(finalResults[2].replace(/\+/g, " "));
        },
        startScan() { // 开始扫码
            this.isScanning = true
            this.$nextTick(() => {
                this.html5QrCode = new Html5Qrcode('scanning');
                this.html5QrCode.start({
                    facingMode: "environment"
                }, {
                    fps: 10,
                    aspectRatio: 1, // 4:3：1.333334 16:9：1.777778 1:1：1.0
                    qrbox: {
                        width: 280,
                        height: 280
                    },
                }, (decodedText, decodedResult) => {
                    this.subData.device_sn = JSON.parse(decodedText).scaleno
                    this.subData.order_id = JSON.parse(decodedText).orderid
                    this.bindOrder()
                }, errorMessage => {
                },
                ).catch(err => { });
            })
        },
        stopScan() { // 结束扫码
            this.html5QrCode.stop().then(() => {
                this.isScanning = false
            })
        },
        async bindOrder() {
            const { data: res } = await this.$https.post('/mobileh5/scan/bind-order?access_token=' + window.sessionStorage.getItem("access_token"), this.subData, { timeout: 2000 })
            if (res.resultCode == "1") {
                this.stopScan() // 结束扫码
                this.getList()
                this.$toast({ type: 'success', message: res.message })
            } else {
                this.$toast({ type: 'fail', message: res.message })
            }
            return
        },
        async getList() {
            const { data: res } = await this.$https.post('/mobileh5/scan/order-list?access_token=' + window.sessionStorage.getItem("access_token"), { timeout: 2000 })
            if (res.resultCode == "1") {
                this.list = res.resultInfo
            }
        },
    },
}
</script>

<style lang="less" scoped>
[v-cloak] {
    display: none
}

.page {
    background-color: #f1f4f9;
    user-select: none;
    width: 100%;
    height: 100vh;
}

.content {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.search_content {
    width: 100%;
    height: 54px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .search {
        width: 85%;
    }

    .scan_img {
        width: 15%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        img {
            width: 26px;
            height: 26px;
        }
    }
}


.list_conetnt {
    width: 100%;
    height: calc(100vh - 62px);
    margin-top: 8px;
    box-sizing: border-box;
    padding: 0 10px;

    .item {
        background-color: #fff;
        width: 100%;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 8px;
        margin-bottom: 10px;

        .row {
            width: 100%;
            height: 36px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.scan {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;

    .scan-box {
        width: 100%;
    }

    .close-scan {
        position: absolute;
        top: 20px;
        right: 20px;

        svg {
            width: 40px;
            height: 40px;
        }
    }
}

.start-scan {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 200px;
    height: 50px;
    background: #00aaff;
    color: #fff;
    border-radius: 10px;
}
</style>