<template>
  <div id="app" :style="{ display: 'block' }">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app"
}
</script>

<style lang="less">
[v-cloak] {
  display: none
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}
</style>
