<template>
    <div class="page">
        <div class="content">
            <div class="tab">
                <van-tabs v-model="activeName" sticky color="#31b158" @change="onChange" title-active-color="#31b158">
                    <van-tab title="开票申请" name="1">
                        <van-cell @click="toKaipiaoOrder" isLink title="请选择开票订单"
                            :value="ids.length > 0 ? '已选' + ids.length + '单' : '请选择开票订单'">
                        </van-cell>
                        <van-cell-group>
                            <van-field bindinput="payChange" v-model="pay_amount" clearable label="交易金额(元)"
                                input-align="right" placeholder="请输入交易金额" />
                        </van-cell-group>
                        <div class="buttomclass">
                            <button @click="handleSubmit" class="buttomitem">确 定</button>
                        </div>
                    </van-tab>
                    <van-tab title="开票记录" name="2">
                        <div class="list_conetnt" v-if="list.length > 0">
                            <van-collapse v-model="activeNames">
                                <div class="item" v-for="(item, index) in list" :key="index">
                                    <div class="row">
                                        <div>开票时间: {{ item.create_at }}</div>
                                        <div>
                                            <van-tag type="success" size="medium">已开票</van-tag>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div>开票金额(元):</div>
                                        <div>
                                            {{ item.fapiao_amount }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div>交易金额(元):</div>
                                        <div class="num">
                                            {{ item.pay_amount }}
                                        </div>
                                    </div>
                                    <van-collapse-item title="详情" name="3">
                                        <div class="row">
                                            <div>发票:</div>
                                            <div>
                                                <van-image class="pic" :data-src="item.url" fit="fill" :src="item.url"
                                                    width="60" height="60">
                                                </van-image>
                                                <van-image-preview v-model="showImg"
                                                    :images="icons"></van-image-preview>
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 10px;">
                                            <div>发票号:</div>
                                            <div>
                                                {{ item.fapiao_no }}
                                            </div>
                                        </div>
                                    </van-collapse-item>
                                </div>
                            </van-collapse>
                        </div>
                        <div v-else>
                            <van-empty description="暂无开票记录！" />
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeName: "1",
            ids: [],
            pay_amount: "",
            activeNames: [],
            list: [],
            access_token: "",
            mobile: "",
            showImg: false,
            icons: [],
            imgTest: {}
        }
    },
    created() {
        if (this.getParameterByName("ids")) {
            this.ids = JSON.parse(this.getParameterByName("ids"))
            this.getAmount()
        }
        this.mobile = this.getParameterByName("mobile")
        var that = this
        if (!window.sessionStorage.getItem("access_token")) {
            this.$dialog.alert({
                title: "提醒",
                message: '您暂未登录！是否前往登录?',
                showConfirmButton: true,
                showCancelButton: true
            }).then(() => {
                that.$router.push({
                    path: '/Login',
                    query: {
                        mobile: that.mobile
                    }
                });
            }).catch(() => {
                that.$router.push({
                    path: '/index',
                    query: {
                        mobile: that.mobile
                    }
                });
            });
        } else {
            this.access_token = window.sessionStorage.getItem("access_token")
        }
    },
    mounted() {
        this.pushHistory()
        //  监听popstate事件
        window.addEventListener('popstate', this.listenPopstate);
    },

    beforeDestroy() {
        window.removeEventListener('popstate', this.listenPopstate);
    },
    methods: {
        listenPopstate() {
            if (this.$route.path === '/KaipiaoApply') {
                this.$router.push({
                    path: '/index',
                    query: {
                        mobile: this.mobile
                    }
                });
            }
            //  跳转小程序页面相关逻辑
        },
        pushHistory() {
            window.history.pushState(null, null, null)
        },

        showPreview(url) {
            this.icons.push(url);
            this.showImg = true;
        },
        // 解析URL
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            //匹配所有符合条件的，并取最后一个
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'g');
            var results = url.match(regex);
            var tempResults = results != null && results[results.length - 1] != undefined ? results[results.length - 1] : '';
            var finalResults = regex.exec(tempResults);
            if (!finalResults) return "";
            if (!finalResults[2]) return '';
            return decodeURIComponent(finalResults[2].replace(/\+/g, " "));
        },
        toKaipiaoOrder() {
            this.$router.push({
                path: '/KaipiaoOrder',
                query: {
                    ids: JSON.stringify(this.ids)
                }
            })
        },
        async handleSubmit() {
            if (!this.pay_amount) {
                this.$toast({ type: 'fail', message: "请先输入开票金额！" });
                return;
            }
            if (this.ids.length == 0) {
                this.$toast({ type: 'fail', message: "请先选择开票订单！" });
                return;
            }
            const { data: res } = await this.$https.post('/mobileh5/order/fapiao-apply?access_token=' + window.sessionStorage.getItem("access_token"), { ids: this.ids.toString(), amount: this.pay_amount }, { timeout: 2000 })
            if (res.resultCode == "1") {
                this.$toast({ type: 'success', message: res.message });
                this.ids = []
                this.pay_amount = ""
            } else {
                this.$toast({ type: 'fail', message: res.message });
            }
            return
        },
        async getAmount() {
            if (this.ids.length > 0) {
                const { data: res } = await this.$https.post('/mobileh5/order/get-amount?access_token=' + window.sessionStorage.getItem("access_token"), { ids: this.ids.toString() }, { timeout: 2000 })
                if (res.resultCode == "1") {
                    this.pay_amount = res.resultInfo.amount
                }
                return
            }
        },
        async getList() {
            const { data: res } = await this.$https.post('/mobileh5/order/fapiao-list?access_token=' + window.sessionStorage.getItem("access_token"), { timeout: 2000 })
            if (res.resultCode == "1") {
                this.list = res.resultInfo
            }
            return
        },
        onChange(e) {
            if (e == 2) {
                this.getList()
            }
        },
    },
}
</script>

<style lang="less" scoped>
[v-cloak] {
    display: none
}

.page {
    background-color: #f1f4f9;
    user-select: none;
    width: 100%;
    height: 100vh;
}

.content {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.buttomclass {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100vw;
}

.buttomitem {
    background: #00b470;
    border-radius: 22px;
    box-shadow: 0 5px 12px 0 rgba(0, 180, 112, .25);
    color: #fff;
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 16px;
    font-weight: 500;
    margin-top: 180px;
    width: 343px;
    border: none;
    height: 45px;
}

.list_conetnt {
    width: 100%;
    height: calc(100vh - 60px);
    margin-top: 8px;
    box-sizing: border-box;
    padding: 0 10px;

    .item {
        background-color: #fff;
        width: 100%;
        // height: 160px;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 8px;
        margin-bottom: 10px;

        .row {
            width: 100%;
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    /deep/ .van-cell {
        width: 100%;
        height: 44px;
        line-height: 44px;
        padding: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
    }
}

.num {
    font-weight: 600;
    font-style: 18px;
    color: #31b158;
}
</style>